import Storage from "@/util/Storage";
import {setSpecialProduct} from "@/Api/ShopAdmin";
import {Toast} from "vant";
import {ProductBarStore} from "@/views/SocialCircle/ProductBar/Store";
import {SelectCommodityStore} from "@/views/ShopAdmin/SelectCommodity/Store";

function getSelectCont(){ // SetSelectCommoditySelData
    let data = SelectCommodityStore.getSelectCommoditySelData
    if ( data && data.commodity && JSON.stringify(data.commodity) != "{}" ){
        return data.commodity
    }else{
        return {}
    }
}

export function addProduct() {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    const BarData = ProductBarStore.getSelectProductBar
    const ProductData = getSelectCont()
    if ( JSON.stringify(BarData) == "{}" ){
        Toast("请选择类型")
        return Promise.reject()
    }
    if (JSON.stringify(ProductData) == "{}"){
        Toast("请选择商品")
        return Promise.reject()
    }
    let body:any = {
        productId:ProductData.id || '',onSaleId:BarData.id || ''
    }
    Toast.loading({ duration:0,message:"添加中~~" })
    return Promise.resolve(setSpecialProduct({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}
